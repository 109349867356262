<template>
  <layout-stepper>
    <template #body>
      <b-link @click="$router.push({name: 'step_samples'})" v-if="!has_slug">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        Previous Step
      </b-link>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">Add some options</h1>
            <p>Options are add-on services or extra content that buyers purchase to complement your deals.</p>
            <b-button variant="flat-secondary" class="my-2 p-2" @click="addOption" :disabled="options.length > 3">
              <feather-icon icon="PlusIcon"></feather-icon>  
              Add Option
            </b-button>
            <b-pagination
              pills
              v-model="current_page"
              :total-rows="options.length"
              :per-page="per_page"
              class="justify-content-center"
              v-if="options.length > 1"
            ></b-pagination>
            <div v-for="(option, index) in optionsFiltered()" :key="index">
              <div class="text-danger text-right cursor-pointer" @click="deleteOption(option)" v-if="options.length > 0">Eliminar</div>
              <b-form-group label="Title" label-for="input-title" class="form-group-layout">
                <b-form-input id="input-title" v-model="option.title" trim></b-form-input>
              </b-form-group>
              <b-form-group label="Description" label-for="input-description" class="form-group-layout">
                <b-form-textarea id="input-description" v-model="option.description" rows="3"></b-form-textarea>
              </b-form-group>
              <b-row>
                <b-form-group label="Additional Price" label-for="input-price" class="col-12 col-md-6 form-group-layout">
                    <b-input-group prepend="$" id="input-price">
                    <cleave
                        v-model="option.price"
                        :options="options_number"
                        class="form-control"
                        :raw="false"
                    />
                    </b-input-group>
                </b-form-group>
                <b-form-group label="Days For Delivery" label-for="input-delivery" class="col-12 col-md-6 form-group-layout">
                    <b-form-select :options="days_delivery_options" id="input-delivery" v-model="option.days_delivery"></b-form-select>
                </b-form-group>
              </b-row>
            </div>
            <b-col class="col-12 d-flex justify-content-center">
              <b-button variant="primary" class="my-2" :disabled="false" @click="save()">{{has_slug ? 'Save and preview' : 'Sabe and next step'}}</b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import utils from '@/libs/utils';
import Cleave from 'vue-cleave-component';
import creator_services from '@/services/creator';

export default {
  name: 'options',
  components: {
    BRow,   
    BCol,
    BButton,
    BLink,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    layoutStepper,
    BInputGroup,
    BFormSelect,
    BPagination,
    Cleave,
  },
  data() {
    return {
      utils,
      steps: null,
      options: [],
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      days_delivery_options: [0, 1, 2, 3, 7, 10],
      current_page: 1,
      per_page: 1,
      proposal: {}
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    optionsFiltered () {
      const options = this.options;
      return options.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page);
    },
    getData() {
      if (this.$route.params.slug !== undefined) { 
        creator_services.detailProposal(this.$route.params.slug).then((response) => {
          this.options = response.options;
          this.proposal = response;
          this.steps.uuid = response.uuid;
        })
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        if (this.steps.options) this.options = this.steps.options;
      }
    },
    save() {
      const new_options = []
      this.options.filter((option) => {
        if (typeof option.price === 'string' || isNaN(option.price)) option.price = parseInt(option.price.split(',').join(''));
        new_options.push({...option});
      });
      if (this.has_slug !== undefined) {
        new_options.map((option) => {
          if (option.uuid) {
            creator_services.updateElement(this.proposal.uuid, option.uuid, option, 'option');
          } else {
            creator_services.addOptionOrRequest(this.proposal.uuid, {options: [option]}, 'option');
          }
        });
        this.$router.push({name: 'step_preview', params: {slug: this.proposal.slug}})
      } else {
        this.steps['options'] = new_options;
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({name: 'step_requests'});
        if (new_options.length > 0)
          creator_services.addOptionOrRequest(this.steps.uuid, {options: new_options}, 'option');
      }
    },
    addOption() {
      if (this.options.length < 4) {
        this.options.push(
          {
            index: Math.random().toString(36).substr(2, 18),
            price: 3,
            title: `Any title`,
            description: 'Any description',
            days_delivery: 2,
          }
        ) 
      }
    },
    deleteOption(option) {
      if (option.uuid) {
        creator_services.deleteElement(this.proposal.uuid, option.uuid, 'option').then(() => {
          this.options = this.options.filter((item) => item.uuid !== option.uuid);
        });
      } else {
        this.options = this.options.filter((item) => item.index !== option.index);
      }
      this.current_page = this.options.length === 1 ? this.options.length : this.options.length - 1;
    }
  },
}
</script>
<style>
.form-group-layout > label {
  text-align: left;
}
.btn-group-toggle {
  display:initial;
}
</style>